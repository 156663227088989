import * as React from "react"
import { Link,graphql } from "gatsby"
import Navbar from '../components/Navbar.js'
import Layout from '../components/Layout.js'
import '../styles/global.css'
import * as styles from '../styles/home.module.css'
import { StaticImage } from "gatsby-plugin-image"


export default function Home({  }) {

  return (
      <Layout>
        <section className={styles.header}>
          <div>
            <h2>Design</h2>
            <h3>Develop & Design</h3>
            <p>UX Designer & Web Developer</p>
            <Link className={styles.btn} to="/projects">My Projects</Link>
          </div>
          <StaticImage src="../images/banner.png" alt="site banner" style={{ maxwidth:'100%' }} />
        </section>
      </Layout>
  )
}
